<script setup lang="ts">
const emit = defineEmits(["login"]);
</script>

<template>
  <div>
    <el-button
      round
      size="small"
      class="mr-5"
      type="danger"
      @click="
        emit('login', {
          email: 'qwe@qwe.qwe',
          password: 'qweqweqwe',
        })
      "
    >
      log in as seller
    </el-button>
    <el-button
      round
      size="small"
      class="mr-5"
      type="success"
      @click="
        emit('login', {
          email: 'asd@asd.asd',
          password: 'asdasdasd',
        })
      "
    >
      log in as buyer
    </el-button>
    <el-button
      round
      size="small"
      class="mr-5"
      type="success"
      plain
      @click="
        emit('login', {
          email: 'par@par.par',
          password: 'parparpar',
        })
      "
    >
      log in as partner
    </el-button>
    <el-button
      round
      size="small"
      class="mr-5"
      type="warning"
      @click="
        emit('login', {
          email: 'zxc@zxc.zxc',
          password: 'zxczxczxc',
        })
      "
    >
      log in as moder
    </el-button>
    <el-button
      round
      size="small"
      class="mr-5"
      plain
      type="warning"
      @click="
        emit('login', {
          email: 'xcv@xcv.xcv',
          password: 'xcvxcvxcv',
        })
      "
    >
      log in as admin
    </el-button>
  </div>
</template>

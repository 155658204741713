<script setup lang="ts">
const { t } = useI18n();
useHead({
  title: t("p-login-head-title"),
  meta: [
    {
      name: "description",
      content: t("p-login-head-description"),
    },
    {
      name: "keywords",
      content: t("p-login-head-keywords"),
    },
  ],
});
import { fetchEmailForPasswordReset } from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
const { signIn, data: userData } = useAuth();
const UseNotifications = useNotificationsStore();
const localePath = useLocalePath();
const { notificationsDisconnect } = UseNotifications;
definePageMeta({
  middleware: [
    (to, from) => {
      if (!to.query.redirect && !from.path.includes("/confirm")) {
        to.query.redirect = from.path;
      }
      const { data: user } = useAuth();
      if (user.value) {
        return navigateTo("/");
      }
    },
  ],
});
const token = useCookie("auth.token");
const refrechToken = useCookie("auth.refresh-token");
const route = useRoute();
const router = useRouter();
const loginFormRef = ref();
const loading = ref(false);
const logoStatus = ref<"error" | "loading" | "success">();
const isDev = process.env.NODE_ENV === "development";
const showResetPassword = ref(false);
const passwordResetted = ref(false);
const emailToReset = ref();
const emailToResetForm = ref();

const show2FAForm = ref(false);
const code = ref();
const tfaForm = ref();

interface LoginFormType {
  email: string;
  password: string;
  tfaCode?: string;
}

const loginForm = reactive<LoginFormType>({
  email: "",
  password: "",
});

const rules = computed(() => {
  return {
    email: [
      {
        required: true,
        message: t("p-login-rules-email"),
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: t("p-login-rules-password"),
        trigger: "blur",
      },
    ],
  };
});

const submitForm = async (): Promise<void> => {
  if (loading.value) {
    return;
  }
  const form: LoginFormType = {
    email: loginForm.email,
    password: loginForm.password,
  };
  if (show2FAForm.value) {
    form.tfaCode = code.value;
  }
  let valid = false;
  if (show2FAForm.value) {
    valid = await tfaForm.value.validate();
  } else {
    valid = await loginFormRef.value.validate();
  }
  if (!valid) return;
  loading.value = true;
  await logIn(form).finally(() => {
    loading.value = false;
  });
};
const codeInput = ref();
const logIn = async (form: LoginFormType): Promise<void> => {
  logoStatus.value = "loading";
  return signIn(form, { redirect: false })
    .then(() => {
      router.push(localePath((route.query.redirect as string) || "/"));
    })
    .catch(async ({ data }) => {
      let message = data?.data?.message || t("p-login-error-default");
      let alert = false;
      let title = " ";
      const failedLockCount = 3;
      switch (data?.data.code) {
        case 1015:
          show2FAForm.value = true;
          await nextTick();
          codeInput.value.focus();
          return;
        case 1046:
          message = t("p-login-error-2fa-wrong");
          alert = true;
          break;
        case 1040:
          message = t("p-login-error-user-not-found");
          break;
        case 1048:
          message = t("p-login-error-email-verification");
          alert = true;
          break;
        case 1063:
          message = t("p-login-error-suspended");
          alert = true;
          break;
        case 1425:
          title = t("p-login-error-locked-title");
          message = `${t("p-login-error-locked-message", { failedLockCount })}`;
          alert = true;
          break;
        case 1020:
          title = t("p-login-error-wrong-password-title");
          if (data?.data.failedAttemptsCount >= failedLockCount) {
            message = `${t("p-login-error-locked-message", {
              failedAttemptsCount: data?.data.failedAttemptsCount,
            })}`;
          } else {
            message = `${t("p-login-error-wrong-password-message", {
              failedAttemptsCount: data?.data.failedAttemptsCount,
              failedLockCount,
            })}`;
          }
          alert = true;
          break;
      }
      logoStatus.value = "error";
      if (alert) {
        ElMessageBox.alert(message, title, {
          type: "error",
        });
      } else {
        ElMessage.error(message);
      }
    });
};
const submitPasswordReset = () => {
  emailToResetForm.value.validate(async (valid: boolean) => {
    if (!valid) return;
    loading.value = true;
    try {
      await fetchEmailForPasswordReset(emailToReset.value);
      passwordResetted.value = true;
      ElMessage.success(t("p-login-password-reset-success"));
    } catch ({ data }: any) {
      ElMessage.error(data?.data?.message || t("p-login-password-reset-error"));
    }
    loading.value = false;
  });
};
onMounted(() => {
  if (!userData.value) {
    token.value = null;
    refrechToken.value = null;
    notificationsDisconnect();
  } else {
    router.replace(localePath("/"));
  }
});
const onDebugLogin = (form: LoginFormType) => {
  loginForm.email = form.email;
  loginForm.password = form.password;
  submitForm();
};
</script>

<template>
  <div class="default-width">
    <el-row :gutter="20">
      <el-col :span="12" :xs="24">
        <div class="login-card" style="max-width: 400px; margin: 20px auto">
          <h2 class="text-center">
            <DebugLogins v-if="isDev" @login="onDebugLogin" />
            <span class="logo-cont">
              <Logo :status="logoStatus" />
            </span>
            {{ t("p-login-title") }}
          </h2>
          <el-card v-show="!show2FAForm && !showResetPassword">
            <ClientOnly>
              <template #fallback>
                <el-skeleton :rows="6" animated />
              </template>
              <el-form
                ref="loginFormRef"
                :model="loginForm"
                :rules="rules"
                label-position="top"
                class="login-form"
              >
                <el-form-item :label="t('p-login-label-email')" prop="email">
                  <el-input
                    size="large"
                    v-model="loginForm.email"
                    :placeholder="t('p-login-email-placeholder')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="t('p-login-label-password')"
                  prop="password"
                >
                  <el-input
                    size="large"
                    type="password"
                    show-password
                    v-model="loginForm.password"
                    :placeholder="t('p-login-password-placeholder')"
                    @keyup.enter.native="submitForm"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-button
                    round
                    type="primary"
                    size="small"
                    text
                    @click="showResetPassword = true"
                  >
                    {{ t("p-login-forgot-password") }}
                  </el-button>
                </el-form-item>
                <el-form-item>
                  <el-row class="w-full">
                    <el-button
                      round
                      size="large"
                      class="w-full"
                      type="primary"
                      @click="submitForm"
                      :loading="loading"
                    >
                      {{ t("p-login-submit-button") }}
                    </el-button>
                  </el-row>
                </el-form-item>
              </el-form>
            </ClientOnly>
          </el-card>
          <el-card v-show="show2FAForm">
            <p>{{ t("p-login-2fa-prompt") }}</p>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form
                  :model="{ code: code }"
                  ref="tfaForm"
                  @submit.prevent="() => {}"
                >
                  <el-form-item prop="code" :rules="[{ required: true }]">
                    <el-input
                      type="number"
                      ref="codeInput"
                      class="font-size-2 v-50%"
                      size="default"
                      :controls="false"
                      v-model="code"
                      :placeholder="t('p-login-2fa-placeholder')"
                      @keydown.enter="submitForm"
                    />
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="12">
                <el-button
                  round
                  size="default"
                  type="primary"
                  @click="submitForm"
                  :loading="loading"
                >
                  {{ t("p-login-2fa-button") }}
                </el-button>
              </el-col>
            </el-row>
          </el-card>
          <el-card v-show="showResetPassword">
            <template v-if="passwordResetted">
              <h3>{{ t("p-login-password-reset-success-title") }}</h3>
              <p class="mt-5 mb-5">
                {{ t("p-login-password-reset-success-message") }}
              </p>
              <el-alert :closable="false">
                {{ t("p-login-password-reset-alert") }}
              </el-alert>
              <div class="mt-3 text-right">
                <el-button
                  round
                  size="default"
                  @click="
                    showResetPassword = false;
                    passwordResetted = false;
                  "
                  :disabled="loading"
                >
                  {{ t("p-login-back-to-login") }}
                </el-button>
              </div>
            </template>
            <template v-else>
              <h3>{{ t("p-login-forgot-password-title") }}</h3>
              <p>
                {{ t("p-login-forgot-password-message") }}
              </p>
              <el-alert :closable="false">
                {{ t("p-login-password-reset-instructions") }}
              </el-alert>
              <el-row class="mt-5">
                <el-col :span="-1" class="mr-2">
                  <el-form
                    :model="{ emailToReset: emailToReset }"
                    ref="emailToResetForm"
                    @submit.prevent="() => {}"
                    label-width="auto"
                  >
                    <el-form-item
                      prop="emailToReset"
                      label="Email"
                      :rules="[
                        { required: true, message: t('p-login-enter-email') },
                      ]"
                    >
                      <el-input
                        class="font-size-2"
                        size="default"
                        v-model="emailToReset"
                        :placeholder="t('p-login-email-placeholder')"
                        @keydown.enter="submitPasswordReset"
                      />
                    </el-form-item>
                    <el-form-item label=" ">
                      <el-button
                        round
                        size="default"
                        @click="
                          showResetPassword = false;
                          passwordResetted = false;
                        "
                        :disabled="loading"
                      >
                        {{ t("p-login-cancel") }}
                      </el-button>
                      <el-button
                        round
                        size="default"
                        type="primary"
                        @click="submitPasswordReset"
                        :loading="loading"
                      >
                        {{ t("p-login-proceed") }}
                      </el-button>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </template>
          </el-card>

          <el-row class="pt-5">
            <nuxt-link
              class="el-link el-link--primary is-underline"
              to="/registration"
            >
              {{ t("p-login-go-to-registration") }}
            </nuxt-link>
          </el-row>
        </div>
      </el-col>
      <el-col :span="12" :xs="24" class="text-center">
        <div class="logo-cont">
          <Logo :status="logoStatus" />
        </div>
        <WidgetStats />
        <h4>{{ t("p-login-welcome-title") }}</h4>
        <p>
          {{ t("p-login-welcome-message-1") }} <br />
          {{ t("p-login-welcome-message-2") }} <br />
          {{ t("p-login-welcome-message-3") }} <br />
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.social-logo {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  &.google {
    background-image: url(/img/google.png);
  }
  &.apple {
    background-image: url(/img/apple.png);
    height: 25px;
    margin-top: -4px;
  }
}
.logo-cont {
  width: 200px;
  margin: 20px auto;
  display: inline-block;
  @media screen and (max-width: 768px) {
    display: none;
  }
  h2 & {
    width: 100px;
    margin: 0;
    vertical-align: middle;
    margin-right: 20px;
    display: none;
    max-width: var(--el-screen-xs);

    @media screen and (max-width: 768px) {
      display: inline-block;
    }
  }
}
</style>
